import React, {useState, useEffect} from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import './AccountSettings.scss';

import {getMe, changePassword, requestChangeEmail, cancelChangeEmail} from '@repository/UserRepository';

function AccountSettings() {
  const [isEditEmail, setIsEditEmail] = useState(false);
  const [changeEmailInputs, setChangeEmailInputs] = useState({
    current_email: '',
    new_email: '',
  });
  const [changePasswordInputs, setChangePasswordInputs] = useState({
    current_password: '',
    new_password: '',
    new_password_confirmation: '',
  });
  const [changeEmailErrors, setChangeEmailErrors] = useState({});
  const [changePasswordErrors, setChangePasswordErrors] = useState({});
  const [successAlert, setSuccessAlert] = useState({
    is_shown: false,
    type: '' // email || password
  });
  const [isLoadingChangeEmail, setIsLoadingChangeEmail] = useState(false);
  const [isLoadingCancelNewEmail, setIsLoadingCancelNewEmail] = useState(false);
  const [isLoadingChangePassword, setIsLoadingChangePassword] = useState(false);
  const [hasNewEmailRequest, setHasNewEmailRequest] = useState(false);
  const [isLoadData, setIsLoadData] = useState(true);
  const [isShowPasswords, setIsShowPasswords] = useState({
    current_password: false,
    new_password: false,
    new_password_confirmation: false,

  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    handleGetUser();
  }, []);

  const handleGetUser = () => {
    setIsLoadData(true);
    getMe()
      .then((res) => {
        setHasNewEmailRequest(res.data.new_email);
        let newEmail = res.data.new_email ? res.data.new_email : res.data.email;
        setChangeEmailInputs({
          ...changeEmailInputs,
          current_email: res.data.email,
          new_email: newEmail,
        });
        setIsLoadData(false);
      })
      .catch((err) => {
        setIsLoadData(false);
        console.log(err.response);
      });
  }

  const hasValidChangeEmailInputs = () => {
    if (
      !changeEmailInputs.new_email ||
      changeEmailInputs.new_email.trim() === changeEmailInputs.current_email.trim()
    ) {
      return false;
    }
    return true;
  }

  const hasValidChangePasswordInputs = () => {
    if (
      !changePasswordInputs.current_password ||
      !changePasswordInputs.new_password ||
      !changePasswordInputs.new_password_confirmation
    ) {
      return false;
    }
    return true;
  }

  const handleOnEmailInputsChanged = (e) => {
    let label = e.target.name;
    let value = e.target.value;
    setChangeEmailInputs((currentValue) => (
      {
        ...currentValue,
        [label]: value,
      }
    ));
  }

  const handleOnPasswordInputsChanged = (e) => {
    let label = e.target.name;
    let value = e.target.value;
    setChangePasswordInputs((currentValue) => (
      {
        ...currentValue,
        [label]: value,
      }
    ));
  }

  const handleChangeEmail = () => {
    setIsLoadingChangeEmail(true);
    setChangeEmailErrors({});
    setSuccessAlert({
      is_shown: false,
      type: '' // email || password
    });
    requestChangeEmail(changeEmailInputs.new_email)
      .then((res) => {
        setHasNewEmailRequest(true);
        setIsLoadingChangeEmail(false);
        setSuccessAlert({
          is_shown: true,
          type: 'email'
        });
        setIsEditEmail(false);
      })
      .catch((err) => {
        if (err.response.data.status_code === 422) {
          setChangeEmailErrors(err.response.data.errors);
        }
        setIsLoadingChangeEmail(false);
        console.log(err.response);
      });
  }

  const handleCancelChangeEmail = () => {
    setIsLoadingCancelNewEmail(true);
    setChangeEmailErrors({});
    setSuccessAlert({
      is_shown: false,
      type: '' // email || password
    });
    cancelChangeEmail()
      .then((res) => {
        setHasNewEmailRequest(false);
        setIsLoadingCancelNewEmail(false);
      })
      .catch((err) => {
        if (err.response.data.status_code === 422) {
          setChangeEmailErrors(err.response.data.errors);
        }
        setIsLoadingCancelNewEmail(false);
        console.log(err.response);
      });
  }

  const handleChangePassword = () => {
    setIsLoadingChangePassword(true);
    setChangePasswordErrors({});
    setSuccessAlert({
      is_shown: false,
      type: '' // email || password
    });
    changePassword(changePasswordInputs)
      .then(() => {
        setIsLoadingChangePassword(false);
        setSuccessAlert({
          is_shown: true,
          type: 'password'
        });
      })
      .catch((err) => {
        if (err.response.data.status_code === 422) {
          setChangePasswordErrors(err.response.data.errors);
        } else {
          setChangePasswordErrors({
            current_password: [err.response.data.message],
          });
        }
        setIsLoadingChangePassword(false);
        console.log(err.response);
      })
  }

  return (
    <div id="account-settings" className="container py-5 d-flex flex-column flex-grow-1">
      {successAlert.is_shown && successAlert.type == 'email' && (
        <div className="asap-body text-philadelphia-blue py-2 px-3 alert-email mb-4">
          E-mail change confirmation has been sent. Please check and verify your new e-mail address now.
        </div>
      )}
      {successAlert.is_shown && successAlert.type == 'password' && (
        <div className="asap-body text-philadelphia-blue py-2 px-3 alert-password mb-4">
          Your password has been changed successfully.
        </div>
      )}
      <div className="dosis-title-1 mb-3">Account Settings</div>

      {isLoadData ? (
        <div className="mdi mdi-loading mdi-spin mdi-48px m-auto" />
      ) : (
        <Tabs>
          <Tab eventKey="email" title="E-mail Address">
            <div className="row pt-4">
              <div className="col-xl-5 col-lg-6 col-md-7">
                {!isEditEmail ? (
                  <>
                    <div className={`form-group d-flex flex-column-reverse ${hasNewEmailRequest ? 'mb-1' : 'mb-3'}`}>
                      <input
                        id="email"
                        className="form-control"
                        placeholder="e.g. johnsmith@mail.com"
                        name="current_email"
                        value={changeEmailInputs.current_email}
                        onChange={handleOnEmailInputsChanged}
                        required
                        disabled
                      />
                      <label htmlFor='email' className='input-label'>
                        E-mail address <i className={`mdi ${hasNewEmailRequest ? 'mdi-alert-circle text-warning' : 'mdi-check-circle text-jade-green'} ml-1`} />
                      </label>
                    </div>
                    {hasNewEmailRequest && (
                      <div className="asap-caption text-harbor-gray mb-3">
                        Please check your e-mail <b className="text-azure-blue">{changeEmailInputs.new_email}</b> to confirm your change request.{' '}
                        You won’t be able to change your e-mail until the request is confirmed.
                      </div>
                    )}
                    {hasNewEmailRequest ? (
                      <div className="d-flex">
                        <button
                          className="button-sm button-primary mr-3"
                          onClick={handleChangeEmail}
                          disabled={isLoadingCancelNewEmail || isLoadingChangeEmail}>
                          {isLoadingChangeEmail ? <div className="mdi mdi-loading mdi-spin" /> : "Resend Confirmation"}
                        </button>
                        <button
                          className="button-sm button-default"
                          onClick={handleCancelChangeEmail}
                          disabled={isLoadingCancelNewEmail || isLoadingChangeEmail}>
                          {isLoadingCancelNewEmail ? <div className="mdi mdi-loading mdi-spin" /> : "Cancel Change"}
                        </button>
                      </div>
                    ) : (
                      <button className="button-sm button-primary" onClick={() => setIsEditEmail(true)}>
                        Change E-mail Address
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    <div className={'form-group d-flex flex-column-reverse mb-2'}>
                      <input
                        id="email"
                        className="form-control"
                        placeholder="e.g. johnsmith@mail.com"
                        name="new_email"
                        value={changeEmailInputs.new_email}
                        onChange={handleOnEmailInputsChanged}
                        onKeyPress={event => {
                          if (event.key === 'Enter' && !(isLoadingChangeEmail || !hasValidChangeEmailInputs())) {
                            handleChangeEmail();
                          }
                        }}
                        required
                      />
                      <label htmlFor='email' className='input-label'>
                        E-mail address
                      </label>
                    </div>
                    <div className={`asap-caption text-harbor-gray ${changeEmailErrors.new_email ? "mb-1" : "mb-3"}`}>
                      Current e-mail address: {changeEmailInputs.current_email}
                    </div>
                    {changeEmailErrors.new_email && (
                      <div className="asap-caption-1 text-apple-red mb-3">{changeEmailErrors.new_email[0]}</div>
                    )}
                    <div className="d-flex">
                      <button
                        className="button-sm button-primary mr-3"
                        onClick={handleChangeEmail}
                        disabled={isLoadingChangeEmail || !hasValidChangeEmailInputs()}>
                        {isLoadingChangeEmail ? <div className="mdi mdi-loading mdi-spin" /> : "Submit Change"}
                      </button>
                      <button className="button-sm button-default" onClick={() => setIsEditEmail(false)} disabled={isLoadingCancelNewEmail || isLoadingChangeEmail}>
                        Cancel Change
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Tab>
          <Tab eventKey="password" title="Password Security">
            <div className="asap-headline text-coal-black my-4">
              Change Your Password
            </div>
            <div className="row">
              <div className="col-lg-5 col-md-6">
                <div className={`form-group d-flex flex-column-reverse position-relative ${changePasswordErrors.current_password ? "mb-1" : "mb-3"}`}>
                  <input
                    id="current-password"
                    className="form-control input-password"
                    placeholder="Enter your current password"
                    type={isShowPasswords.current_password ? "text" : "password"}
                    name="current_password"
                    value={changePasswordInputs.current_password}
                    onChange={handleOnPasswordInputsChanged}
                    onKeyPress={event => {
                      if (event.key === 'Enter' && !(isLoadingChangePassword || !hasValidChangePasswordInputs())) {
                        handleChangePassword();
                      }
                    }}
                    required
                  />
                  <label htmlFor='current-password' className='input-label'>
                    Current Password
                  </label>
                  <div
                    className={`text-steel-gray mdi ${isShowPasswords.current_password ? 'mdi-eye' : 'mdi-eye-off'} mdi-24px icon-eye`}
                    onClick={() => {
                      setIsShowPasswords((prevValue) => ({
                        ...prevValue,
                        current_password: !prevValue.current_password,
                      }));
                    }}
                  />
                </div>
                {changePasswordErrors.current_password && (
                  <div className="asap-caption-1 text-apple-red mb-3">{changePasswordErrors.current_password[0]}</div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5 col-md-6">
                <div className={'form-group d-flex flex-column-reverse position-relative mb-2'}>
                  <input
                    id="new-password"
                    className="form-control input-password"
                    placeholder="Enter new password"
                    type={isShowPasswords.new_password ? "text" : "password"}
                    name="new_password"
                    value={changePasswordInputs.new_password}
                    onChange={handleOnPasswordInputsChanged}
                    onKeyPress={event => {
                      if (event.key === 'Enter' && !(isLoadingChangePassword || !hasValidChangePasswordInputs())) {
                        handleChangePassword();
                      }
                    }}
                    required
                  />
                  <label htmlFor='new-password' className='input-label'>
                    New Password
                  </label>
                  <div
                    className={`text-steel-gray mdi ${isShowPasswords.new_password ? 'mdi-eye' : 'mdi-eye-off'} mdi-24px icon-eye`}
                    onClick={() => {
                      setIsShowPasswords((prevValue) => ({
                        ...prevValue,
                        new_password: !prevValue.new_password,
                      }));
                    }}
                  />
                </div>
                <div className={`asap-caption text-harbor-gray ${changePasswordErrors.new_password ? "mb-1" : "mb-3"}`}>
                  Password must contain at least eight characters categories among the following: Uppercase characters (A-Z) Lowercase characters (a-z)
                </div>
                {changePasswordErrors.new_password && (
                  <div className="asap-caption-1 text-apple-red mb-3">{changePasswordErrors.new_password[0]}</div>
                )}
              </div>
              <div className="col-lg-5 col-md-6">
                <div className={`form-group d-flex flex-column-reverse position-relative ${changePasswordErrors.new_password_confirmation ? "mb-1" : "mb-3"}`}>
                  <input
                    id="re-enter-password"
                    className="form-control input-password"
                    placeholder="Re-enter new password"
                    type={isShowPasswords.new_password_confirmation ? "text" : "password"}
                    name="new_password_confirmation"
                    value={changePasswordInputs.new_password_confirmation}
                    onChange={handleOnPasswordInputsChanged}
                    onKeyPress={event => {
                      if (event.key === 'Enter' && !(isLoadingChangePassword || !hasValidChangePasswordInputs())) {
                        handleChangePassword();
                      }
                    }}
                    required
                  />
                  <label htmlFor='re-enter-password' className='input-label'>
                    Re-enter New Password
                  </label>
                  <div
                    className={`text-steel-gray mdi ${isShowPasswords.new_password_confirmation ? 'mdi-eye' : 'mdi-eye-off'} mdi-24px icon-eye`}
                    onClick={() => {
                      setIsShowPasswords((prevValue) => ({
                        ...prevValue,
                        new_password_confirmation: !prevValue.new_password_confirmation,
                      }));
                    }}
                  />
                </div>
                {changePasswordErrors.new_password_confirmation && (
                  <div className="asap-caption-1 text-apple-red mb-3">{changePasswordErrors.new_password_confirmation[0]}</div>
                )}
              </div>
            </div>
            <button
              className="button-sm button-primary"
              onClick={handleChangePassword}
              disabled={isLoadingChangePassword || !hasValidChangePasswordInputs()}>
              {isLoadingChangePassword ? <div className="mdi mdi-loading mdi-spin" /> : "Change Password"}
            </button>
          </Tab>
        </Tabs>
      )}
    </div>
  );
}

export default AccountSettings;
